import React from "react"

import Button from "@material-ui/core/Button"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
const imgShare = `${process.env.AWS_S3_URL}/assets/share-social-new.jpg`
const NotFoundPage = () => (
  <Layout page="404">
    <SEO
      title="BEST HAVEN POOLVILLA"
      description="แหล่งรวมบ้านพักพูลวิลล่าพร้อมสระว่ายน้ำส่วนตัว
    และบ้านพักตากอากาศที่มากที่สุดและราคาดีที่สุดในโซนเขาใหญ่
    และบริเวณใกล้เคียงโดยสามารถจองผ่านทางเราโดยตรงได้ทันที"
      image={imgShare}
    />
    <center>
      <h1>404</h1>
    </center>
    <center>
      <p style={{ fontSize: "20px" }}>ขออภัย ไม่พบหน้าเว็บไซต์ที่คุณต้องการ</p>
    </center>
    <center>
      <Link to={`/`} style={{ textDecoration: "none" }}>
        <Button
          size="large"
          style={{ marginTop: "30px" }}
          variant="contained"
          color="secondary"
        >
          กลับหน้าแรก
        </Button>
      </Link>
    </center>
  </Layout>
)

export default NotFoundPage
